<template>
<div class="btn">
<button @click="getPosts(modelValue),getDomains(modelValue)">
  <div v-if="modelValue">
  Проверить домен: {{modelValue}} и другие
</div>
<div v-else>
  Проверить домены
</div>
</button>
<div v-if="left">{{left}}</div>
</div>
<div id="domains" class="container">
<h3 v-if="errorMsg">{{errorMsg}}</h3>

<div class="line" v-for="post in posts" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in ru" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in com" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in net" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in org" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in xyz" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in biz" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
    </div>

<div class="line" v-for="post in io" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in co" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in cc" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in pro" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in me" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in site" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in click" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>

<div class="line" v-for="post in best" :key="post.num">
  <a rel="nofollow" target="_blank" v-bind:class="post.class" v-bind:href="post.domaingo">{{post.domain}} - {{post.status}}</a>
</div>


</div>
</template>

<script>
import axios from 'axios';

export default {
  name: "CheckDomain",
  props: ['modelValue'],
  data(){
      return {
          posts: [],
          ru: [],
          com: [],
          net: [],
          org: [],
          xyz: [],
          biz: [],
          io: [],
          co: [],
          cc: [],
          pro: [],
          me: [],
          site: [],
          click: [],
          best: [],
          errorMsg: '',
          left: '',
      }
  },
  methods: {
    // Запросы всех доменов
    getDomains(val) {
      let self = this
      const arrayZones = [
        'ru', 
        'com', 
        'net', 
        'org', 
        'xyz', 
        'biz', 
        'io', 
        'co', 
        'cc', 
        'pro', 
        'me', 
        'site', 
        'click', 
        'best'
        ];

arrayZones.forEach(function(element, i){

  if (val.split('.')[1] == element) return;
   setTimeout(function(){

    Func(val, element)

  const num = arrayZones.length - i;
  self.left = 'Осталось проверить: ' + num;

   }, 2000 * ++i)
});


function Func(val, element) {

      axios({
                method: 'POST',
                url: 'https://tapl.ru/api/checkdomain.php',
                params: { domain: val.split('.')[0] + '.' + element }
            })
      .then((response) => {
        
        self[element] = response.data

      })
      .catch((error) => {
          console.log(error);
          self.errorMsg = 'Ошибка загрузки'
      })

}


    },
    // Запрос для первоначального домена
      getPosts(val) {

// Очищаем
var elements = document.querySelectorAll('#domains .line a');
for (var i = 0; i < elements.length; i++) {
  elements[i].textContent=''
}


      axios({
                method: 'POST',
                url: 'https://tapl.ru/api/checkdomain.php',
                params: { domain: val }
            })
      .then((response) => {
          this.posts = response.data;        
      })
      .catch((error) => {
          console.log(error);
          this.errorMsg = 'Ошибка загрузки'
      })
      }
  },
  /*
    created() {
        this.getPosts(1)
      },
    mounted() {
      this.posts = setInterval(() => {
        this.getPosts(1)
        }, 5000)
      },
  */
};
</script>

<style scoped>
.btn {
    min-width: 100px;
    margin: 0 auto;
    text-align: center;
}
.btn button {
    color: #fff;
    background: #000;
    height: 40px;
    border: 0 solid #000;
    font-size: 18px;
    cursor: pointer;
}
.line {
  min-width: 20%;
  position: relative;
  background: #ecf0f3;
  margin-bottom: 20px;
  padding: 10px 5px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
    6px 6px 10px rgba(0, 0, 0, 0.2);
}
.line a {
    font-size: 24px;
    text-decoration: none;
}
.green {
    color: #00c73d;
}
.red {
    color: #e7051a;
}
</style>