<template>
<div class="btn red">
<button @click="getPhrase(modelValue)">
  <div v-if="modelValue">
  Узнать что ищут с: {{modelValue}}
</div>
<div v-else>
  Узнать что ищут
</div>
</button>
</div>
<div class="container">
<h3 v-if="errorMsg">{{errorMsg}}</h3>
    <div class="line" v-for="post in posts"
      :key="post.num"
      :phrase="post.phrase"
      :data="post.data"
    >
  <p class="green">Ищут с: {{post.phrase}}</p> 
  <p class="find"><span v-html="post.data"></span></p> 
    </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  name: "CheckPhrase",
  props: ['modelValue'],
  data(){
      return {
          posts: [],
          errorMsg: '',
          msg: '',
      }
  },
  methods: {
      async getPhrase(val) {
      axios({
                method: 'POST',
                url: 'https://tapl.ru/api/checkphrase.php',
                params: { phrase: val }
            })
      .then((response) => {
        console.log(response.data);
          this.posts = response.data
      })
      .catch((error) => {
          console.log(error);
          this.errorMsg = 'Ошибка загрузки'
      })
      }
  },
  /*
    created() {
        this.getPosts(1)
      },
    mounted() {
      this.posts = setInterval(() => {
        this.getPosts(1)
        }, 5000)
      },
  */
};
</script>

<style scoped>
.btn {
    min-width: 100px;
    margin: 0 auto;
    text-align: center;
}
.btn.red button {
    color: #fff;
    background: rgb(223, 21, 21);
    height: 40px;
    border: 0 solid #000;
    font-size: 18px;
    cursor: pointer;
}
.line {
  min-width: 30%;
  position: relative;
  background: #ecf0f3;
  margin-bottom: 20px;
  padding: 10px 5px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
    6px 6px 10px rgba(0, 0, 0, 0.2);
}
.line a {
    color: #000;
    font-size: 30px;
}
.green {
    color: #00c73d;
}
.red {
    color: #e7051a;
}
.find {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
</style>