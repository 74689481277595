<template>
  <div class="check">
    <input v-model="value" placeholder="Введите">
  </div>
    <CheckDomain v-model="value"/>
    <CheckPhrase  v-model="value"/>
</template>

<script>
import CheckDomain from "./components/CheckDomain.vue";
import CheckPhrase from "./components/CheckPhrase.vue";

export default {
  name: "App",
  components: { CheckDomain, CheckPhrase },
  data() {
    return{
      value: ''
    }
  },
};
</script>

<style>
body {
  background: #ecf0f3;
}
h1 {
    text-align: center;
    font-family: Montserrat,sans-serif;
    font-size: 22px;
}
.logo {
    display: block;
    width: 150px;
    margin: 0 auto;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
  padding: 10px 0;
}
.check {
    margin: 0 auto;
    margin-bottom: 5px;
    text-align: center;
}
input {
    min-width: 300px;
    height: 40px;
    font-family: Montserrat,sans-serif;
    font-size: 22px;
    box-shadow: -6px -6px 10px hsl(0deg 0% 100% / 80%), 6px 6px 10px rgb(0 0 0 / 20%);
    color: #6f6cde;
    border: 1px solid #e1e1e1;
}
</style>